<template>
  <div class="navigation-back" @click="navigate">
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 5H1" stroke="#F2F2F2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 1L1 5L5 9" stroke="#F2F2F2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <div>{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    label: {
      type: String,
      default: 'Вернуться к курсу',
    },
    routeToPush: {
      type: String,
      default: null,
    },
  },
  methods: {
    navigate() {
      if (this.$route.name === 'CertificateConstructor') {
        return this.$router.push(`/${this.routeToPush}`).catch((e) => console.log(e));
      }
      if (this.routeToPush === null && this.$route.params?.courseId
        && this.$route.params?.programId) {
        this.$router.push(
          `/course-user/${this.$route.params.programId}/${this.$route.params.courseId}`,
        );
        return;
      }
      if (this.routeToPush === null) {
        return;
      }
      this.$router.push(this.routeToPush);
    },
  },
};
</script>
